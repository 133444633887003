import { createRouter, createWebHistory } from "vue-router";

import About from "@/views/About.vue";
import DefaultPage from "@/views/DefaultPage.vue";
import Home from "@/views/Home.vue";
// import PageBuilder from "@/views/PageBuilder.vue";
import Project from "@/views/Project.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
	
    {
        path: "/over",
        name: "About",
        component: About,
    },
	
    {
        path: "/project/:alias",
        name: "Project",
        component: Project,
    },

	// {
	// 	path: "/informatie/:alias",
	// 	name: "PageBuilder",
	// 	component: PageBuilder,
	// },
	
    {
        path: "/:alias",
        name: "DefaultPage",
        component: DefaultPage,
    },
];

const router = createRouter({
    scrollBehavior (to, from, savedPosition) {
        return savedPosition ? savedPosition : { top: 0 };
    },
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
