<template>
    <a class="button" target="_blank" v-if="typeof(url) == 'string'" :href="url">
        <span>
            Bekijk deze website
        </span>

        <i class="fas fa-external-link-alt"></i>
    </a>
</template>


<script>
export default {
    props: {
        url: String,
    },
}
</script>