export default {
    methods: {
        slideTo(e)
        {
            let target = e.target;

            while (target.tagName.toLowerCase() != 'a')
            {
                target = target.parentNode;
            }

            let el = document.querySelector(target.attributes.href.value);
            
            // smooth scroll to element and align it at the bottom
            el.scrollIntoView({ behavior: 'smooth', block: 'start'});
        },
    },
}