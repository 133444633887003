<template>

    <header>

        <router-link :to="{ name: 'Home' }">
            <img src="img/logo-compact.svg" alt="DIZERO" />
        </router-link>


        <nav :class="open ? 'open' : ''">
            <div class="fluid">

                <span v-for="(item, id) in nav" :key="['item', id].join('-')">
                    
                    <a v-if="item.type == 'anchor'" :href="'#' + item.alias" @click.prevent="toContact">
                        
                        <i :class="item.icon"></i>

                        <span>
                            {{ item.name }}
                        </span>

                    </a>


                    <router-link v-else :to="to(item)" @click="open = false">

                        <i :class="item.icon"></i>

                        <span>
                            {{ item.name }}
                        </span>

                    </router-link>

                </span>

            </div>
        </nav>


        <aside>
            <a href="https://www.instagram.com/dizeroNL" target="_blank">
                <i class="fab fa-instagram"></i>
            </a>

            <a href="https://www.facebook.com/dizeroNL" target="_blank">
                <i class="fab fa-facebook-f"></i>
            </a>

            <a href="https://twitter.com/dizeroNL" target="_blank">
                <i class="fab fa-twitter"></i>
            </a>
        </aside>


        <a href="#" class="menu-toggle" @click.prevent="open = !open">
            <span>
				<span></span>
				<span></span>
				<span></span>
			</span>
        </a>

    </header>
    
</template>


<script>
import { mapGetters } from 'vuex';

import slideTo from '@/mixins/slideTo';

var vm;

export default {
    mixins: [
        slideTo,
    ],


    data()
    {
        return {
            open: false,
        };
    },
    
    
    computed: {
        ...mapGetters([
            'structure',
        ]),


        nav()
        {
            let nav = [];

            for (let i = 0; i < vm.structure.length; i++)
            {
                let item = vm.structure[i];

                if (item.hidemenu == 0)
                {
                    nav.push(item);
                }
            }

            return nav;
        },
    },


    methods: {
        to(item)
        {
            let p = item.path.length > 1 ? item.path.substr(0, (item.path.length - 1)) : item.path;

            return p;
        },


        toContact(e)
        {
            vm.slideTo(e);
            vm.open = false;
        },
    },


    created()
    {
        vm = this;
    },


    
}
</script>