<template>

    <main>
        <a href="#" class="back-button" @click.prevent="$router.go(-1)">
            <i class="fas fa-lg fa-arrow-to-left"></i>

            <span>
                terug
            </span>
        </a>

        <section class="hero background-image" :style="heroBg">
            <div>
            
                <h1>
                    {{ page.longtitle }}
                </h1>

                <h5>
                    {{ types }}
                </h5>

                <website :url="page.variables.website" />

            </div>
        </section>


        <section class="description" data-color="dark">
            <div class="content">

                <div v-html="page.content" />

                <website :url="page.variables.website" />

            </div>
        </section>


        <font v-for="(f, id) in fonts" :key="id" :entry="f" />
    
    
        <contact />

        
        <link v-if="page.hasOwnProperty('variables') && page.variables.hasOwnProperty('fontStylesheet')" rel="stylesheet" :href="page.variables.fontStylesheet" />
    
    </main>

</template>


<script>
import { mapGetters } from 'vuex';

import Contact from '@/components/Contact.vue';
import Website from '@/components/ButtonWebsite.vue';
import Font from '@/components/FontDisplay.vue';

var vm;

export default {
    components: {
        Contact,
        Font,
        Website,
    },

    
    props: {
        page: Object,
    },


    computed: {
        ...mapGetters([
            'base',
        ]),


        fonts()
        {
            let hasFonts = Object.prototype.hasOwnProperty.call(vm.page.variables, 'fonts'),
                f = hasFonts ? vm.page.variables.fonts.split('\r\n') : [];

            if (f.length === 0 && hasFonts) f = [vm.page.variables.fonts];

            return f;
        },


        heroBg()
        {
            return Object.prototype.hasOwnProperty.call(vm.page.variables, 'image') ? "background-image: url('" + vm.base + '/' + vm.page.variables.image + "')" : '';
        },


        types()
        {
            return vm.page.variables.types.split('||').join(' / ');
        },
    },


    created()
    {
        vm = this;
    },
}
</script>