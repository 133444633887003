<template>

    <main>
    
        <section class="white">
            
            <div v-html="content" />
            
        </section>
        
        
        <contact />

    </main>

</template>


<script>
import Contact from '@/components/Contact.vue';

var vm;

export default {
    components: {
        Contact,
    },

    
    props: {
        page: Object,
    },


    computed: {
        content()
        {
            return '<h1>' + vm.title +'</h1>' + vm.page.content;
        },
        
        title()
        {
            return vm.page.longtitle.length > 0 ? vm.page.longtitle : vm.page.pagetitle;
        },
    },


    created()
    {
        vm = this;
    },
}
</script>