<template>

    <section class="contact" id="contact">

        <div>
            <div>
                <h2>
                    Ben je zover?
                </h2>

                <p>
                    Als je mij jouw probleem wil laten oplossen, neem dan contact op
                    via dit contactformulier, of stuur een directe mail.
                </p>

                <a href="mailto:info@dizero.nl" class="button">
                    <i class="far fa-at"></i>

                    Stuur een mail
                </a>
            </div>        
        </div>


        <div>
            <form>
                
                <transition name="fade" mode="out-in">

                    <loader v-if="processing" />

                </transition>


                <div v-if="success === false" class="feedback error">
                    <p>
                        Helaas is met verzenden van je bericht mislukt.
                    </p>

                    <p>
                        Probeer het nog eens, of mail direct naar

                        <a href="mailto:info@dizero.nl">
                            info@dizero.nl
                        </a>
                    </p>
                </div>

                <p v-if="success">
                    Bedankt voor je bericht. Er wordt z.s.m. op gereageerd.
                </p>

                <ul v-else>
                    <li :class="contact.name.length > 0 ? 'filled' : ''">
                        <input type="text" v-model="contact.name" placeholder="Naam..." />
                    </li>
                    
                    <li :class="validEmail ? 'filled' : ''">
                        <input type="text" v-model="contact.email" placeholder="E-mail..." />
                    </li>

                    <li :class="contact.message.length > 0 ? 'filled' : ''">
                        <textarea v-model="contact.message" placeholder="Bericht..." />
                    </li>

                    <li>
                        <a href="#" :class="buttonClasses" @click.prevent="submitForm">
                            <i class="fas fa-paper-plane"></i>

                            Versturen
                        </a>
                    </li>
                </ul>
                
            </form>
        </div>
    </section>

</template>


<script>
import axios from 'axios';

import { mapGetters } from 'vuex';

import Loader from '@/components/Loader.vue';

var vm;

export default {
    components: {
		Loader,
	},


	data()
    {
        return {
            contact: {
                name: '',
                email: '',
                message: '',
            },
            processed: false,
			submitted: false,
			success: null,
        };
    },


    computed: {
        ...mapGetters([
            'base',
        ]),


        buttonClasses()
        {
            let cl = ['button'];

            if (!vm.valid)
            {
                cl.push('disabled');
            }

            return cl.join(' ');
        },


		processing()
		{
			return vm.processed !== vm.submitted;
		},


        valid()
        {
            if (vm.contact.name.length === 0) return false;
            if (vm.contact.email.length === 0) return false;
            if (vm.contact.message.length === 0) return false;

            if(!vm.validEmail) return false;

            return true;
        },


        validEmail()
        {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(vm.contact.email).toLowerCase());
        },
    },


    methods: {
        async submitForm()
		{
			vm.submitted = true;

            let formData = new FormData();

            // for (let i = 0; i < vm.files.length; i++)
            // {
            // 	let f = vm.files[i];
            //
            // 	formData.append('files[' + i + ']', f);
            // }

            formData.append('action', 'contact');
            formData.append('data', JSON.stringify(vm.contact));

			if (process.env.NODE_ENV == 'development')
			{
				setTimeout(() => {
					vm.success = true;
					vm.processed = true;
				}, 3000);
			}
			else
			{
				await axios.post(vm.base + 'api/forms/', formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then(response => {
					vm.processed = true;
					vm.success = response.data.success;
				})
				.catch(function (error)
				{
					console.log(error);
				});
			}
		},
    },


    created()
    {
        vm = this;
    },
}
</script>