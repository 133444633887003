<template>

    <main>
        
        <section v-if="hero !== false" :style="heroBg" class="hero background-image">
            <div>
                <h4>
                    project
                </h4>

                <img :src="base + hero.variables.logo" :alt="hero.pagetitle" />
                
                <h5>
                    {{ projectTypes(hero) }}
                </h5>

                <p>
                    <router-link class="button" :to="{ name: 'Project', params: { alias: hero.alias } }">
                        <i class="fas fa-search"></i>
                        Over deze case
                    </router-link>

                    <a class="button secondary" :href="['#project', hero.alias].join('-')" @click.prevent="slideTo">
                        <i class="fas fa-images"></i>

                        Meer projecten
                    </a>
                </p>
            </div>
        </section>


        <introduction v-if="intro" :page="intro" />


        <section class="project-bar" v-for="(s, id) in projects" :key="['section', id].join('-')" :id="['project', s.alias].join('-')">
            <router-link :to="{ name: 'Project', params: { 'alias': s.alias } }">
                <div class="content" :style="bgImage(s)">
                
                    <div class="title">
                        
                        <img :src="base + s.variables.logo" :alt="s.pagetitle" />
                        
                        <h5>
                            {{ projectTypes(s) }}
                        </h5>

                    </div>

                    <!--<div class="mockup">
                        mockup
                    </div>-->

                </div>
            </router-link>
        </section>


        <contact />

    </main>

</template>


<script>
import { mapGetters, mapActions } from 'vuex';

import Contact from '@/components/Contact.vue';
import Introduction from '@/components/Introduction.vue';

import slideTo from '@/mixins/slideTo';

var vm;

export default {
    mixins: [
        slideTo,
    ],
    
    
    components: {
        Contact,
        Introduction,
    },


    props: {
        page: Object,
    },


    data()
    {
        return {
            intro: false,
        };
    },


    computed: {
        ...mapGetters([
            'base',
            'projects',
        ]),


        hero()
        {
            let mp = false,
                keys = Object.keys(vm.projects);
            
            if (keys.length > 0)
            {
                mp = vm.projects[keys[0]];
            }

            return mp;
        },


        heroBg()
        {
            // If there's no main project yet, return an empty string
            if (vm.hero === false) return '';


            return Object.prototype.hasOwnProperty.call(vm.hero.variables, 'image') ? "background-image: url('" + vm.base + '/' + vm.hero.variables.image + "')" : '';
        },
    },


    methods: {
        ...mapActions([
            'getPageById',
        ]),


        bgImage(s)
        {
            // If there's no main project yet, return an empty string
            if (s === false) return 'none';


            return Object.prototype.hasOwnProperty.call(s.variables, 'image') ? "background-image: url('" + vm.base + '/' + s.variables.image + "')" : 'test';
        },


        projectTypes(pr)
        {
            // If there's no main project yet, return an empty string
            if (pr === false) return '';


            // Create the types string
            let types = '';


            if (Object.prototype.hasOwnProperty.call(pr.variables, 'types'))
			{
				types = pr.variables.types.split('||').join(' / ');
			}
            
            
            return types;
        },
    },


    created()
    {
        vm = this;


        // Load the intro section
        vm.getPageById(vm.page.variables.aboutId)
        .then((result) => {
            vm.intro = result;
        });
    },
}
</script>