import { createStore } from "vuex";

import axios from 'axios';

var base = 'https://dizero.nl/';

export default createStore({
    state: {
		base: base,
		duration: 650,
		pages: [],
		siteName: 'DIZERO',
		structure: [],
	},
	
	
	getters: {
		base: state => {
			return state.base;
		},
		
		
		duration: state => {
			return state.duration;
		},
		
		
		pages: state => {
			return state.pages;
		},


		projects: state => {
			let pr = {};

			for (let i = 0; i < state.pages.length; i++)
			{
				let p = state.pages[i];

				if (p.template == 3)
				{
					pr[p.menuindex] = p;
				}
			}

			return pr;
		},
		
		
		siteName: state => {
			return state.siteName;
		},
		
		
		structure: state => {
			return state.structure;
		},
	},


	mutations: {
		setPages (state, value)
		{
			state.pages = value;
		},
		
		
		setStructure (state, value)
		{
			state.structure = value;
		},
	},


	actions: {
        _initialize: (context, payload) => {
			return axios.get(base + 'api/initialize/', { params: payload })
			.then(response => {
                context.commit('setStructure', response.data.structure);
				context.commit('setPages', response.data.pages);
			})
			.catch(function (error)
			{
				console.log(error);
			});
		},

        
		getPageById: ({ state }, payload) => {
			// Loop through all pages
			for (let i = 0; i < state.pages.length; i++)
			{
				let p = state.pages[i];
				
			
				// If a match is found, return it
				if (p.id == payload) return p;
			}
			
			
			// If no match was found, return false
			return false;
		},


		getProjectTypes: (context, payload) => {
			// Check if there are any types set
			if (Object.prototype.hasOwnProperty.call(payload, 'types'))
			{
				let types = payload.variables.types.split('||');
				
				return types.join(', ');
			}


			// If nothing was found, return an empty string
			return '';
		},
	},
});
