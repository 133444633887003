<template>

    <section class="intro">
        
        <div class="lead-in">
            <h2>
                Pascal Maranus
            </h2>

            <h4>
                vormgever &amp; ontwikkelaar
            </h4>

            <p v-for="(p, id) in intro" :key="id" v-html="p" />

            <router-link :to="{ name: 'DefaultPage', params: { alias: page.alias } }" class="button">
                <i :class="page.variables.icon"></i>

                Meer over DIZERO
            </router-link>

        </div>


        <div class="photo">
            <figure :style="photo" />
        </div>

    </section>

</template>


<script>
import { mapGetters } from 'vuex';

var vm;

export default {
    props: {
        page: { Object, Boolean },
    },


    computed: {
        ...mapGetters([
            'base',
        ]),


        intro()
        {
            return vm.page.introtext.split('\r\n\r\n');
        },


        photo()
        {
            let url = [vm.base, vm.page.variables.image].join('');

            return "background-image: url('" + url + "')";
        },
    },


    created()
    {
        vm = this;
    },
}
</script>