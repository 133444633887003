<template>
    <section class="font" :style="style">
        <div>
            <h3>
                {{ title }}
            </h3>

            <div>
                <p v-for="n in 2" :key="n">
                    abcdefghijklm
                    <br />
                    nopqrstuvwxyz
                </p>
            </div>
        </div>
    </section>
</template>


<script>
var vm;

export default {
    props: {
        entry: String,
    },


    computed: {
        font()
        {
            let e = vm.entry.split(':'),
                f = {
                    family: e[0],
                    name: e[1],
                    weight: e[2],
                };

            return f;
        },


        fontFamily()
        {
            return vm.font.family.toLowerCase().split(' ').join('-');
        },


        style()
        {
            return [
                'font-family: ' + vm.fontFamily,
                'font-weight: ' + vm.font.weight,
            ].join('; ');
        },


        title()
        {
            return [vm.font.family, vm.font.name].join(' ');
        },
    },


    created()
    {
        vm = this;
    },
}
</script>