<template>

    <footer>
        
        <div>
            <i class="far fa-copyright"></i>

            {{ year }}
			
			<span class="dizero">
				{{ siteName }}
			</span>

			-
			
			Alle rechten voorbehouden
        </div>

		<div>
			<i class="fas fa-shield-alt"></i>

			<router-link to="/disclaimer">
				Disclaimer
			</router-link>
		</div>

        <div>
			<i class="fas fa-file-contract"></i>

            <a href="https://dizero.nl/downloads/av-zeroproductions.pdf" target="_blank">
				<span>Algemene Voorwaarden</span>
			</a>
        </div>

    </footer>

</template>


<script>
import { mapGetters } from 'vuex';

export default {
	data()
	{
		return {
			year: new Date().getFullYear(),
		};
	},


	computed: {
		...mapGetters([
			'siteName',
		]),
	},
}
</script>