<template>
	<transition name="load" mode="out-in">
		<article v-if="loaded">
			<teleport to="head">
				<title>
					{{ meta.title }}
				</title>
				
				<meta name="description" :content="meta.description">
				<meta name="keywords" :content="meta.keywords">
				
				<meta property="og:description" :content="meta.description">
				<meta property="og:title" :content="meta.title" />
				<meta property="og:image" :content="meta.image" />
			</teleport>
			

			<app-header />
			

			<router-view v-if="page !== false" v-slot="{ Component }">
				<transition name="fade" mode="out-in">
					<component :is="Component" :key="$route.fullPath" :page="page" />
				</transition>
			</router-view>
			

			<app-footer />
			
		</article>
	</transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import pageData from '@/hooks/page.js';

import AppFooter from '@/components/AppFooter.vue';
import AppHeader from '@/components/AppHeader.vue';

var vm;

export default {
	components: {
		AppFooter,
		AppHeader,
	},

	
	data()
	{
		return {
			duration: 1300,
			transition: 'overlay',
			transitionDefault: 'overlay',
		};
	},


	computed: {
		...mapGetters(['pages', 'siteName', 'structure']),


		loaded()
		{
			return vm.structure.length > 0 && vm.pages.length > 0;
		},


		meta()
		{
			// If no page is loaded yet, return false
			if (Object.keys(vm.page).length === 0) return false;

			let title = vm.page.longtitle.length > 0 ? vm.page.longtitle : vm.page.pagetitle;
			title += ' | ' + vm.siteName;

			let img = vm.page.hasImage ? vm.page.variables.image : 'img/icons/android-chrome-512x512.png';
			
			let meta = {
				description: vm.page.description,
				keywords: vm.page.keywords,
				title: title,
				image: img,
			};

			return meta;
		},
	},


	methods: {
		...mapActions(['_initialize']),

		
		scrollReveal()
		{
			let reveals = document.querySelectorAll('.reveal');
			
			for(let i = 0; i < reveals.length; i++){

				let wHeight = window.innerHeight;
				let revealTop = reveals[i].getBoundingClientRect().top;
				let revealPoint = Math.round(window.innerHeight / 4);

				if(revealTop < wHeight - revealPoint)
				{
					reveals[i].classList.add('active');
				}
				else
				{
					reveals[i].classList.remove('active');
				}
			}
		},
	},


	created()
	{
		vm = this;
		
		vm._initialize();

		window.addEventListener('scroll', vm.scrollReveal);
		window.addEventListener('resize', vm.scrollReveal);
	},


	setup()
	{
		const { page } = pageData();

		return {
			page,
		};
	},


	mounted()
	{
		setTimeout(vm.scrollReveal, vm.duration);
	},
	

	unmounted()
	{
		window.removeEventListener('scroll', vm.scrollReveal);
		window.removeEventListener('resize', vm.scrollReveal);
	},
};
</script>

<style lang="scss">
@import 'assets/css/all.min.css';
@import 'assets/scss/app.scss';
</style>
