<template>

    <main>
    
        <section class="about">
            
            <div class="photo">
                <figure :style="photo" />
            </div>

            <div v-html="content" />
            
        </section>
        
        
        <contact />

    </main>

</template>


<script>
import { mapGetters } from 'vuex';

import Contact from '@/components/Contact.vue';

var vm;

export default {
    components: {
        Contact,
    },

    
    props: {
        page: Object,
    },


    computed: {
        ...mapGetters([
            'base',
        ]),


        content()
        {
            return '<h1>' + vm.title +'</h1>' + vm.page.content;
        },


        photo()
        {
            let url = [vm.base, vm.page.variables.image].join('');

            return "background-image: url('" + url + "')";
        },

        
        title()
        {
            return vm.page.longtitle.length > 0 ? vm.page.longtitle : vm.page.pagetitle;
        },
    },


    created()
    {
        vm = this;
    },
}
</script>